import axios from "axios"
import styled from "styled-components"
import { ChangeEvent, useState, useEffect, useRef, memo } from "react"
import CropOriginalIcon from "@material-ui/icons/CropOriginal"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"
import { useMediaQuery } from "@material-ui/core"
import {
  SWAPSpace,
  Typography,
  CircularProgress,
  Snackbar,
  Modal,
  Tooltip,
  Button,
} from "@yosgo/swap-ui"
import { formatString, parseIdCard, parseImageType } from "@yosgo/swapjs"
import Fade from "./Fade"
interface VerifiedDataType {
  is_draft: boolean
  is_foreigner: boolean
  verified_name: String
  verified_phone: String
  verified_bank_code: String
  verified_bank_id: String
  verified_bank: String
  verified_bank_branch: String
  verified_id_number: String
  verified_address: String
  verified_comment: String
  verified_images: String[]
  verified_state: String
  verified_reject_reson: any
  verified_bank_name: String
}
type Props = {
  prefix?: string
  disabled?: boolean
  userId: string
  userName: string
  fileName?: string
  handleChange: Function
  handleClean: Function
  img: string | undefined
  error?: boolean
  helperText?: string
  uploadingText?: string
  reUploadText?: string
  uploadedText?: string
  uploadText?: string
  disableText?: string
  disableWatermark?: boolean
  defaultIcon?: React.ReactNode
  width?: number | string
  height?: number | string
  showUploadOptions?: boolean
  setOpenCameraWindow?: (openCameraWindow: boolean) => void
  verifiedData?: VerifiedDataType
  setVerifiedData?: (verifiedData: object) => void
  setNameLoading?: (nameLoading: boolean) => void
  setIdLoading?: (idLoading: boolean) => void
  setAddressLoading?: (addressLoading: boolean) => void
  setIdFrontOcrError?: (idFrontOcrError: boolean) => void
  setIdBackOcrError?: (idBackOcrError: boolean) => void
  setStartFrom?: (startFrom: "front" | "back") => void
  isImgPreviewCover?: boolean
  setVerifiedName?: (name: string) => void
  setVerifiedIdNumber?: (number: string) => void
}

const SnackBarMemo = memo(function SnackBarMemo({
  openSnackBar,
  handleCloseSnackBar,
  snackBarMsg,
}: any) {
  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={2000}
      onClose={handleCloseSnackBar}
      message={snackBarMsg}
      variant="error"
    />
  )
})

const RemoveModalMemo = memo(function RemoveModalMemo({
  prefix,
  setIdFrontOcrError,
  setIdBackOcrError,
  openLogoutDialog,
  setOpenLogoutDialog,
  setIsPreview,
  setPreviewImage,
  handleClean,
}: any) {
  return (
    <Modal
      mobile={true}
      size="extraSmall"
      title="確定要刪除這張照片嗎？"
      open={openLogoutDialog}
      disCloseIcon={true}
      onClose={() => setOpenLogoutDialog(false)}
      primaryButton={{
        title: "確認刪除",
        onClick: () => {
          setOpenLogoutDialog(false)
          setIsPreview(false)
          setPreviewImage("")
          handleClean()
          if (prefix === "id1" && setIdFrontOcrError) {
            setIdFrontOcrError(false)
          }
          if (prefix === "id2" && setIdBackOcrError) {
            setIdBackOcrError(false)
          }
        },
        variant: "danger",
      }}
      secondaryButton={{
        title: "取消刪除",
        onClick: () => {
          setOpenLogoutDialog(false)
        },
      }}
      buttonFullWidth
    ></Modal>
  )
})

const UploadModalMemo = memo(function UploadModalMemo({
  openPhotoOptionsModal,
  setOpenPhotoOptionsModal,
  toggleInputClick,
  toggleCameraWindow,
}: any) {
  return (
    <Modal
      maxWidth={640}
      mobile={true}
      fullWidth={true}
      title="請選擇上傳方式"
      open={openPhotoOptionsModal}
      onClose={() => setOpenPhotoOptionsModal(false)}
      footerDisplayColumn={true}
      secondaryButton={{
        title: "取消",
        onClick: () => {
          setOpenPhotoOptionsModal(false)
        },
      }}
    >
      <Button
        fullWidth
        variant="black"
        style={{
          border: "1px solid #CCCCCC",
          display: "flex",
          justifyContent: "left",
          marginBottom: 8,
        }}
        onClick={toggleInputClick}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            color: "black",
          }}
        >
          {image_icon}
          從照片圖庫
        </span>
      </Button>
      <Button
        fullWidth
        variant="black"
        style={{
          border: "1px solid #CCCCCC",
          display: "flex",
          justifyContent: "left",
        }}
        onClick={toggleCameraWindow}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            color: "black",
          }}
        >
          {camera_icon}
          從相機拍照
        </span>
      </Button>
    </Modal>
  )
})

const ImageUploader = (props: Props) => {
  const {
    prefix,
    disabled,
    userId,
    userName,
    fileName,
    handleChange,
    handleClean,
    img,
    error,
    helperText,
    uploadingText,
    reUploadText,
    uploadedText,
    uploadText,
    disableText,
    disableWatermark,
    defaultIcon,
    width,
    height,
    showUploadOptions,
    setOpenCameraWindow,
    verifiedData,
    setVerifiedData,
    setNameLoading,
    setIdLoading,
    setAddressLoading,
    setIdFrontOcrError,
    setIdBackOcrError,
    setStartFrom,
    isImgPreviewCover,
    setVerifiedName,
    setVerifiedIdNumber,
  } = props
  useEffect(() => {
    if (!img) {
      setIsUpload(false)
    }
  }, [img])
  const [loading, setLoading] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [isUpload, setIsUpload] = useState(true)
  const [isDragOver, setIsDragOver] = useState(false)
  /** Dialog */
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true)
  }
  /** SnackBar */
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMsg, setSnackBarMsg] = useState("")
  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
    setSnackBarMsg("")
  }
  const handleSnackBarOpen = (msg: string) => {
    setSnackBarMsg(msg)
    setOpenSnackBar(true)
  }
  const [openPhotoOptionsModal, setOpenPhotoOptionsModal] = useState(false)
  const [progress, setProgress] = useState(0)
  const inputFileRef: any = useRef(null)
  const matchSM = useMediaQuery("(max-width:576px)")
  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      let isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
      const files = e.target.files
      // 大小限制15MB
      if (files[0].size < 15728640) {
        if (
          files[0].type == "image/jpeg" ||
          files[0].type == "image/jpg" ||
          files[0].type == "image/png" ||
          (isIOS && files[0].type == "image/heic")
        ) {
          /**單一檔案上傳 */
          const Upload = (file: any) => {
            return new Promise(async (resolve, reject) => {
              setLoading(true)
              setIsUpload(false)
              setIsPreview(false)
              setProgress(0)
              const formData = new FormData()
              if (disableWatermark) {
                formData.append("disableWatermark", "YES")
              }
              formData.append("photos", file)
              formData.append("id", userId)
              formData.append("name", userName)
              let reader = new FileReader()
              reader.readAsDataURL(file)
              try {
                const response = await axios.post(
                  "https://img-work.swap.work/_watermark",
                  formData,
                  {
                    onUploadProgress: (progressEvent: ProgressEvent) => {
                      let progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                      setProgress(progress)
                    },
                  }
                )

                resolve([response.data[0], reader.result as string])
                setPreviewImage(reader.result as string)
              } catch (error) {
                console.log(`> 圖片上傳錯誤 ${fileName}`)
                reject(error)
              }

              if (
                (prefix === "id1" || prefix === "id2") &&
                !verifiedData?.is_foreigner
              ) {
                // 身分證正面 load 名字+身分證字號 欄位
                if (prefix === "id1" && setNameLoading && setIdLoading) {
                  setNameLoading(true)
                  setIdLoading(true)
                }
                // 身分證正面 load 地址 欄位
                if (prefix === "id2" && setAddressLoading) {
                  setAddressLoading(true)
                }
                try {
                  const response = await axios.post(
                    "https://img-work.swap.work/_ocr",
                    formData,
                    {}
                  )
                  ocrParseAndSet(response.data)
                } catch (error) {
                  console.log(`> ocr圖片上傳錯誤 ${fileName}`)
                  reject(error)
                  if (
                    prefix === "id1" &&
                    setNameLoading &&
                    setIdLoading &&
                    setIdFrontOcrError
                  ) {
                    setNameLoading(false)
                    setIdLoading(false)
                    setIdFrontOcrError(true)
                  }
                  if (
                    prefix === "id2" &&
                    setAddressLoading &&
                    setIdBackOcrError
                  ) {
                    setAddressLoading(false)
                    setIdBackOcrError(true)
                  }
                }
              }
            })
          }
          /**上傳所選圖片 */
          const Uploads = async () => {
            let arr = []
            for (let x = 0; x < files.length; x++) {
              arr.push(Upload(files[x]))
            }
            await Promise.all(arr)
              .then((r: any) => {
                handleChange([r[0][0]], r[0][1])
                setIsPreview(true)
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                setLoading(false)
                setIsDragOver(false)
                setTimeout(() => {
                  setIsUpload(true)
                }, 800)
              })
          }
          await Uploads()
        } else {
          handleSnackBarOpen(
            isIOS
              ? "請使用 jpg, jpeg, heic 或 png 的圖片格式。"
              : "請使用 jpg, jpeg 或 png 的圖片格式。"
          )
        }
      } else {
        handleSnackBarOpen("請選擇檔案大小在15MB以內的圖片檔案。")
      }
    }
  }

  const ocrParseAndSet = (dataString: string) => {
    const stringWithSpacingAndNewLineRemoved = formatString(dataString)
    const type: "id_front" | "id_back" | "bank" | null = parseImageType(
      stringWithSpacingAndNewLineRemoved
    )

    if (prefix === "id1" && type !== "id_front" && setIdFrontOcrError) {
      setIdFrontOcrError(true)
    }

    if (prefix === "id2" && type !== "id_back" && setIdBackOcrError) {
      setIdBackOcrError(true)
    }

    if (prefix === "id1") {
      const data = parseIdCard("front", stringWithSpacingAndNewLineRemoved)
      if (data !== undefined && setVerifiedName && setVerifiedIdNumber) {
        setVerifiedName(data.name)
        setVerifiedIdNumber(data.id)
      }

      if (setIdFrontOcrError) {
        // 沒有回傳資料 => 辨識失敗
        if (data === undefined) {
          setIdFrontOcrError(true)
        } else {
          setIdFrontOcrError(false)
        }
      }

      // 辨識完帶入資料後就可以取消 loading
      if (setNameLoading && setIdLoading) {
        setNameLoading(false)
        setIdLoading(false)
      }
    }

    if (prefix === "id2") {
      const data = parseIdCard("back", stringWithSpacingAndNewLineRemoved)
      if (data !== undefined && setVerifiedData) {
      }

      if (setIdBackOcrError) {
        // 沒有回傳資料 => 辨識失敗
        if (data === undefined) {
          setIdBackOcrError(true)
        } else {
          setIdBackOcrError(false)
        }
      }

      // 辨識完帶入資料後就可以取消 loading
      if (setAddressLoading) {
        setAddressLoading(false)
      }
    }
  }

  const handleBoxClicked = (e: any) => {
    if (showUploadOptions && !openPhotoOptionsModal) {
      e.preventDefault()
      setOpenPhotoOptionsModal(true)
    } else {
    }
  }

  const toggleInputClick = () => {
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click()
    }
    setOpenPhotoOptionsModal(false)
  }

  const toggleCameraWindow = () => {
    setOpenPhotoOptionsModal(false)
    if (setOpenCameraWindow) {
      if (setStartFrom) {
        if (prefix === "id1") {
          setStartFrom("front")
        }
        if (prefix === "id2") {
          setStartFrom("back")
        }
      }
      setOpenCameraWindow(true)
    }
  }

  return (
    <div>
      <Typography variant="subtitle" color="black800">
        {fileName}
      </Typography>
      {fileName ? <SWAPSpace size="s" /> : null}
      <ImageUploaderWrap
        className={error ? "error" : ""}
        style={{ width: width ? width : 240, height: height ? height : 180 }}
      >
        {img && img.length > 0 && !disabled && (
          <button title="delete_picture_btn" 
                  className="clean_btn" onClick={handleOpenLogoutDialog}>
            <div style={{ marginLeft: "1px" }}>
              <Tooltip arrow placement="top" title="刪除照片">
                <CloseIcon fontSize="small"></CloseIcon>
              </Tooltip>
            </div>
          </button>
        )}
        <div
          className="upload_container"
          onClick={(e) => {
            if (matchSM && !img && !disabled && setOpenCameraWindow) {
              handleBoxClicked(e)
            }
          }}
        >
          {img && img.length > 0 ? (
            <Fade>
              {isPreview ? (
                <img
                  alt="preview_cover"
                  src={previewImage}
                  className={
                    isImgPreviewCover ? "image_preview_cover" : "image_preview"
                  }
                />
              ) : (
                <img
                  alt="preview_cover_default"
                  src={img}
                  className={
                    isImgPreviewCover ? "image_preview_cover" : "image_preview"
                  }
                />
              )}
              {isPreview && !disableWatermark && (
                <img
                  alt="white_swap_logo"
                  src="https://swap-img.swap.work/media/member/_logo_watermark.svg"
                  className={
                    isImgPreviewCover ? "image_preview_cover" : "image_preview"
                  }
                />
              )}
            </Fade>
          ) : (
            <div className="upload_status">
              <Fade>
                {defaultIcon ? (
                  defaultIcon
                ) : (
                  <img
                    alt="user-id-card"
                    style={{ width: "48px", height: "36px" }}
                    src="https://swap-img.swap.work/media/member/user_id.png"
                  />
                )}
              </Fade>
            </div>
          )}

          <input
            // enable same data upload
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
              const element = e.target as HTMLInputElement
              element.value = ""
            }}
            ref={inputFileRef}
            className={"file_input"}
            onChange={(e) => {
              handleUpload(e)
            }}
            id={"photoupload" + prefix}
            name={"photoupload" + prefix}
            type="file"
            accept="image/*"
            onDragOver={() => setIsDragOver(true)}
            onDragEnter={() => setIsDragOver(true)}
            onDragLeave={() => setIsDragOver(false)}
            disabled={disabled}
          />
          <label
            className={
              isDragOver
                ? "upload_btn active"
                : img && isUpload && !disabled
                ? "upload_btn is_upload"
                : img && !isUpload && !disabled
                ? "upload_btn success"
                : img && disabled
                ? "upload_btn disabled"
                : "upload_btn"
            }
            htmlFor={"photoupload" + prefix}
            onClick={(e) => {
              if (matchSM && setOpenCameraWindow) {
                handleBoxClicked(e)
              }
            }}
          >
            {loading ? (
              <Typography color="primary800" variant="subtitle">
                <span className="circular_icon_image">
                  <CircularProgress size={16} />
                </span>
                {uploadingText
                  ? `${uploadingText}...${progress}%`
                  : `照片上傳中...${progress}%`}
              </Typography>
            ) : (
              <Typography
                color={
                  img && !isUpload && !disabled
                    ? "success800"
                    : disabled
                    ? "black700"
                    : "primary800"
                }
                variant="subtitle"
              >
                {img && !isUpload && !disabled && (
                  <DoneIcon
                    className="icon_image"
                    style={{ color: "#00821E" }}
                  ></DoneIcon>
                )}
                {!img && !isUpload && !disabled && (
                  <CropOriginalIcon
                    className="icon_image"
                    style={{ color: "#002BA1" }}
                  ></CropOriginalIcon>
                )}
                {img && isUpload && !disabled && (
                  <CropOriginalIcon
                    className="icon_image"
                    style={{ color: "#002BA1" }}
                  ></CropOriginalIcon>
                )}
                {img && isUpload && !disabled
                  ? reUploadText
                    ? reUploadText
                    : "重新上傳圖片"
                  : null}
                {img && !isUpload && !disabled
                  ? uploadedText
                    ? uploadedText
                    : "圖片已上傳"
                  : null}
                {!img && !disabled
                  ? uploadText
                    ? uploadText
                    : "上傳圖片"
                  : null}
                {img && disabled
                  ? disableText
                    ? disableText
                    : "審核中無法編輯圖片"
                  : null}
              </Typography>
            )}
          </label>
        </div>
      </ImageUploaderWrap>
      {error && (
        <Typography
          variant="body2"
          color={error ? "danger800" : "primary"}
          style={{ marginTop: 8 }}
        >
          {helperText}
        </Typography>
      )}

      <SnackBarMemo
        openSnackBar={openSnackBar}
        handleCloseSnackBar={handleCloseSnackBar}
        snackBarMsg={snackBarMsg}
      />
      <RemoveModalMemo
        prefix={prefix}
        setIdFrontOcrError={setIdFrontOcrError}
        setIdBackOcrError={setIdBackOcrError}
        openLogoutDialog={openLogoutDialog}
        setOpenLogoutDialog={setOpenLogoutDialog}
        setIsPreview={setIsPreview}
        setPreviewImage={setPreviewImage}
        handleClean={handleClean}
      />
      <UploadModalMemo
        openPhotoOptionsModal={openPhotoOptionsModal}
        setOpenPhotoOptionsModal={setOpenPhotoOptionsModal}
        toggleInputClick={toggleInputClick}
        toggleCameraWindow={toggleCameraWindow}
      />
    </div>
  )
}

const ImageUploaderWrap = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  &.error {
    border: 1px solid #e20f0f;
  }
  .clean_btn {
    width: 24px;
    height: 24px;
    z-index: 4;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 1px 0;
    margin: 0;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 0px 0px 2px #fff, 0px 4px 12px rgba(0, 0, 0, 0.1);
    &:hover {
      cursor: pointer;
      border-color: #000;
    }
  }
  .upload_status {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -40px);
  }
  .upload_btn {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 48px;
    background: #fff;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    &.is_upload,
    &.success,
    &.disabled {
      z-index: 4;
    }
    p {
      line-height: 48px;
    }
    .icon_image {
      transform: translateY(6px);
      margin-right: 3px;
    }
    .circular_icon_image {
      display: inline-block;
      max-width: 24px;
      margin-right: 3px;
      transform: translateY(3px);
    }
    &.active {
      background: #e6e9f8;
    }
  }
  .upload_container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    .upload_btn.is_upload {
      bottom: -48px;
      transition: 0.3s;
      &:hover {
        background: #e6e9f8;
      }
    }
    &:hover {
      .upload_btn.is_upload {
        bottom: 0;
      }
    }
  }
  input {
    &:hover {
      & + label {
        background: #e6e9f8;
        &.disabled {
          background: #fff;
          cursor: not-allowed;
        }
      }
    }
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px;
    border: 0px;
    box-sizing: border-box;
    outline: 0px;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }
  .image_preview {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .image_preview_cover {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    z-index: 3;
    top: 0;
    left: 0;
  }
`

const image_icon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 11 }}
  >
    <path
      d="M16 16H2V2H16V16ZM16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM10.96 9.29L8.21 12.83L6.25 10.47L3.5 14H14.5L10.96 9.29Z"
      fill="#6F6F6F"
    />
  </svg>
)

const camera_icon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 11 }}
  >
    <path
      d="M12.12 1.99976L13.95 3.99976H18V15.9998H1.99995V3.99976H6.04995L7.87995 1.99976H12.12ZM13 -0.000244141H6.99995L5.16995 1.99976H1.99995C0.89995 1.99976 -4.95911e-05 2.89976 -4.95911e-05 3.99976V15.9998C-4.95911e-05 17.0998 0.89995 17.9998 1.99995 17.9998H18C19.1 17.9998 20 17.0998 20 15.9998V3.99976C20 2.89976 19.1 1.99976 18 1.99976H14.83L13 -0.000244141ZM9.99995 6.99976C11.65 6.99976 13 8.34975 13 9.99975C13 11.6498 11.65 12.9998 9.99995 12.9998C8.34995 12.9998 6.99995 11.6498 6.99995 9.99975C6.99995 8.34975 8.34995 6.99976 9.99995 6.99976ZM9.99995 4.99976C7.23995 4.99976 4.99995 7.23976 4.99995 9.99975C4.99995 12.7598 7.23995 14.9998 9.99995 14.9998C12.76 14.9998 15 12.7598 15 9.99975C15 7.23976 12.76 4.99976 9.99995 4.99976Z"
      fill="#6F6F6F"
    />
  </svg>
)
export default ImageUploader
